var vue = new Vue({
    el: '#Vue__job-search',
    data: {
        search_term: '',
        filter_by_business: '',
        business_filters: [],
        filter_by_city: '',
        city_filters: [],
        filter_by_type: '',
        type_filters: [],
        results: [],
        internal:0
    },
    methods: {
        search:function() {
            var myself = this;

            $.ajax('/fuer-bewerber/search_jobs', {
                method:'get',
                data: {
                    internal:myself.internal,
                    search_term:myself.search_term,
                    filter_by_business: myself.filter_by_business,
                    filter_by_city: myself.filter_by_city,
                    filter_by_type: myself.filter_by_type
                }
            }).done(function(response) {
               myself.results = response.jobs;
               myself.business_filters = response.businesses;
               myself.city_filters = response.cities;
               myself.type_filters = response.types;
            });
        },
        getDetailUrl:function(url)  {
            return url + '?search_term=' + encodeURIComponent(this.search_term)
                + '&filter_by_business=' + encodeURIComponent(this.filter_by_business)
                + '&filter_by_city=' + encodeURIComponent(this.filter_by_city)
                + '&filter_by_type=' + encodeURIComponent(this.filter_by_type)
                + '&internal=' + encodeURIComponent(this.internal);
        }
    },
    computed: {
        chunkedJobList: function() {
            if (_.size(this.results) > 1) {
                return _.chunk(this.results, Math.ceil(_.size(this.results)/2));
            } else {
                return [
                    this.results,
                    []
                ];
            }
        }
    },
    watch: {
        search_term: function (val) {
            this.search();
        },
        filter_by_business: function (val) {
            this.search();
        },
        filter_by_city: function (val) {
            this.search();
        },
        filter_by_type: function (val) {
            this.search();
        }
    }
});
